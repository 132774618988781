import React from 'react';

export default function SelectList(props) {
    return (
        <select style={props.style}
            className={props.className} size={props.size || 4} 
            onChange={e => {props.onChange(props.items[e.target.value])}}
        >
            {props.items.map(
                (v, i) => 
                    <option 
                        key={props.getKey ? props.getKey(v) : v.key} 
                        value={i}
                    >
                        {props.getTitle ? props.getTitle(v) : v.title}
                    </option>
            )}
        </select>
    )
}