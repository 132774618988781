import React from 'react';
import L from 'leaflet';
import settings from '../settings';

const DEFAULT_ZOOM = 11;
const DEFAULT_TILES = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const DEFAULT_ATTRIBUTION = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

export const breakpoints = [-10, 0, 20, 30, 35, 40, 50, 60];
export const colors = ['#200000', '#400000', '#600000', '#800000', '#000680', '#0009bf', '#006007', '#009f0c', '#03ba00']

function color(v) {
    for (let i=0; i<breakpoints.length; i++) {
        console.log(v);
        if (v < breakpoints[i]) {
            return colors[i];
        }
    }
    return colors[breakpoints.length];
}

function popup(scan) {
    return `<ul>
        <li>RF: ${scan.rf}</li>
        <li>MPATH: ${scan.multipath}</li>
        <li>MPX: ${scan.MPX}</li>
        <li>Pilot: ${scan.pilot}</li>
        <li>RDS: ${scan.RDS}</li>
        <li>left: ${scan.left}</li>
        <li>right: ${scan.right}</li>
        <li>SQ: ${scan.signal_quality}</li>
        <li>PI: ${scan.PI}</li>
    </ul>`
}

class Map extends React.Component {
    constructor(props) {
        super(props);
        this.el = React.createRef();
    }

    componentDidUpdate() {
        const geoJSON = this.props.geoJSON;
        if (geoJSON && this.map) {
            if (this.geoJSON) {
                this.geoJSON.remove();
            }
            this.geoJSON = L.geoJSON(geoJSON, {
                pointToLayer: (feature, latlng) => {
                    return L.circleMarker(latlng, {
                        radius: 8,
                        fillColor: color(feature.properties.scan.rf),
                        color: "#000",
                        weight: 1,
                        opacity: 1,
                        fillOpacity: 0.8
                    });
                },
                onEachFeature: (feature, layer) => {layer.bindPopup(popup(feature.properties.scan))}
            }).addTo(this.map);
        }
    }

    componentDidMount() {
        console.log(settings.baseURL);
        this.map = L.map(this.el.current).setView(this.props.center, this.props.zoom || DEFAULT_ZOOM);
        L.tileLayer(this.props.tiles || DEFAULT_TILES, {
            attribution: this.props.attribution || DEFAULT_ATTRIBUTION
        }).addTo(this.map);
    }

    render() {
        return (<div ref={this.el} style={{height: '100%'}}></div>)        
    }
}

export default Map;