import React from 'react';

export default function Navbar() {
    return (
        <nav className="navbar is-primary is-fixed-top" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item">FMData</a>
            </div>
        </nav>
    )
}