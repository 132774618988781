import React, { Component } from "react";
import axios from 'axios';
import "./styles.scss";
import Navbar from "./components/Navbar";
import Map, {breakpoints, colors} from "./components/Map";
import settings from './settings';
import SelectList from './components/SelectList'
import MapLegend from './components/MapLegend';

class App extends Component {
  constructor() {
    super();
    this.state = {
      campagins: [],
      currentCampagin: null,
      tasks: [],
      currentTask: null,
    }
  }

  componentDidMount() {
    axios.get(`${settings.baseURL}/campagins.json`).then(response => {
      if(response.data) {
        this.setState({campagins: response.data});
      }
    });
  }

  loadCampagin(v) {
    axios.get(`${settings.baseURL}/${v.url}`).then(response => {
      if (response.data) {
        this.setState({
          currentCampagin: response.data,
          tasks: Object.keys(response.data).map(v => ({title: v, key: v}))
        });
      }
    });
  }

  setGeoJSON(item) {
    this.setState({currentTask: this.state.currentCampagin[item.key]});
  }

  render() {
    return (
      <div className="is-fullheight">
        <Navbar/>
        <div className="columns is-gapless">
            <div className="column is-one-quarter">
              <div className="fmd-controls">
                <div>
                  <h3>Кампания</h3>
                  <SelectList items={this.state.campagins} onChange={v => this.loadCampagin(v)} getKey={v => v.url} style={{width: '100%'}}/>
                </div>
                <div>
                  <h3>Задание (радиостанция)</h3>
                  <SelectList items={this.state.tasks} style={{width: '100%'}} size={10} onChange={v => this.setGeoJSON(v)}/>
                </div>
              </div>
            </div>
            <div className="column">
              <Map center={[53.9102, 27.5498]} geoJSON={this.state.currentTask}/>
              <MapLegend breakpoints={breakpoints} colors={colors} className="fmd-legend"/>
            </div>
        </div>
      </div>
    )
  }
}

export default App;
