import React from 'react';

export default function MapLegend(props) {
    const items = [];
    for (let i=0; i<=props.breakpoints.length; i++) {
        if (i == 0) {
            items.push(<li key={i}style={{color: props.colors[i]}}>&lt; {props.breakpoints[i]}</li>)
        } else if (i > 0 && i < props.breakpoints.length) {
            items.push(<li key={i} style={{color: props.colors[i]}}>{props.breakpoints[i-1]} ... {props.breakpoints[i]}</li>)
        } else {
            items.push(<li key={i} style={{color: props.colors[i]}}>&gt; {props.breakpoints[i-1]}</li>)
        }
    }    
    return (
        <div style={props.style} className={props.className}>
            RF, dBuV
            <ul>{items}</ul>
        </div>
    );
} 